var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "my-6" },
    [
      _c(
        "v-row",
        { attrs: { justify: "center", "no-gutters": "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "11", lg: "9" } },
            [
              _c(
                "v-row",
                [
                  _c("v-col", { attrs: { md: "6", cols: "12" } }, [
                    _c(
                      "div",
                      [
                        _c("v-img", {
                          staticClass:
                            "full-width px-16 py-4 productDetail-image-box",
                          attrs: {
                            contain: "",
                            src: _vm.currentImage,
                            alt: "",
                            "aspect-ratio": "1:1",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm.product.gallery != undefined &&
                    _vm.product.gallery.length > 0
                      ? _c(
                          "div",
                          { staticClass: "mt-4" },
                          [
                            _c(
                              "v-row",
                              {
                                attrs: {
                                  justify: "center",
                                  align: "center",
                                  "no-gutters": "",
                                },
                              },
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "auto" } },
                                  [
                                    _c("v-icon", {
                                      staticClass: "cursor-pointer",
                                      attrs: { large: "" },
                                      domProps: {
                                        textContent: _vm._s("$chevronLeftIcon"),
                                      },
                                      on: { click: _vm.goPrevious },
                                    }),
                                  ],
                                  1
                                ),
                                _c("v-col", { attrs: { cols: "9" } }, [
                                  _c(
                                    "div",
                                    { staticClass: "mb-5" },
                                    [
                                      _vm.product.gallery.length > 0
                                        ? _c(
                                            "carousel",
                                            {
                                              ref: "carousel",
                                              attrs: {
                                                margin: 25,
                                                items: 4,
                                                dots: false,
                                                nav: false,
                                                mouseDrag: false,
                                                loop: false,
                                              },
                                            },
                                            [
                                              _vm._l(
                                                _vm.product.gallery,
                                                function (image, index) {
                                                  return [
                                                    _c(
                                                      "div",
                                                      {
                                                        key:
                                                          "galleryImage_" +
                                                          index,
                                                        staticClass:
                                                          "text-center cursor-pointer",
                                                        class:
                                                          _vm.currentImage ==
                                                          image
                                                            ? "productDetail-gallery-active"
                                                            : "productDetail-gallery",
                                                        on: {
                                                          mouseenter:
                                                            function () {
                                                              return (_vm.currentImage =
                                                                image)
                                                            },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "v-card",
                                                          {
                                                            attrs: {
                                                              title: "",
                                                              elevation: "0",
                                                            },
                                                          },
                                                          [
                                                            _c("img", {
                                                              staticClass:
                                                                "productDetail-gallery-image mx-auto",
                                                              attrs: {
                                                                src: image,
                                                                alt:
                                                                  "gallery image " +
                                                                  Number(
                                                                    index + 1
                                                                  ),
                                                                title:
                                                                  "gallery image " +
                                                                  Number(
                                                                    index + 1
                                                                  ),
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                }
                                              ),
                                            ],
                                            2
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "auto" } },
                                  [
                                    _c("v-icon", {
                                      staticClass: "cursor-pointer",
                                      attrs: { large: "" },
                                      domProps: {
                                        textContent:
                                          _vm._s("$chevronRightIcon"),
                                      },
                                      on: { click: _vm.goNext },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                  _c(
                    "v-col",
                    { attrs: { md: "6", cols: "12" } },
                    [
                      _c(
                        "div",
                        { staticClass: "font-weight-bold text-md-h4 text-h5" },
                        [_vm._v(_vm._s(_vm.product.name))]
                      ),
                      _vm.product.highestSellingPrice ==
                      _vm.product.lowestSellingPrice
                        ? _c(
                            "v-row",
                            {
                              class:
                                _vm.product.highestDiscountedRate != 0
                                  ? "d-visible"
                                  : "d-invisible",
                              attrs: {
                                align: "center",
                                justify: "space-between",
                                "no-gutters": "",
                              },
                            },
                            [
                              _c("v-col", { attrs: { cols: "5" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "text-caption text-decoration-line-through text_light_gray--text",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$shared.formatCurrency(
                                            _vm.product.highestSellingPrice
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                              _c(
                                "v-col",
                                {
                                  class:
                                    _vm.product.highestDiscountedRate >= 3
                                      ? "d-visible"
                                      : "d-invisible",
                                  attrs: {
                                    cols: "2",
                                    sm: "1",
                                    md: "2",
                                    lg: "2",
                                    xl: "1",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "productContent-discountRate rounded-lg py-1 text-center text-caption text_light_gray--text",
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "white--text text-caption text-uppercase font-weight-bold",
                                        },
                                        [
                                          _vm._v(
                                            "- " +
                                              _vm._s(
                                                _vm.product
                                                  .highestDiscountedRate
                                              ) +
                                              "%"
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm.selectedSku == ""
                        ? _c(
                            "v-row",
                            {
                              class:
                                _vm.product.highestDiscountedRate != 0 ||
                                _vm.product.lowestDiscountedRate != 0
                                  ? "d-visible"
                                  : "d-invisible",
                              attrs: {
                                align: "center",
                                justify: "space-between",
                                "no-gutters": "",
                              },
                            },
                            [
                              _c("v-col", { attrs: { cols: "5" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "text-caption text-decoration-line-through text_light_gray--text",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$shared.formatCurrency(
                                            _vm.product.lowestSellingPrice
                                          ) +
                                            " - " +
                                            _vm.$shared.formatCurrency(
                                              _vm.product.highestSellingPrice
                                            )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                              _c(
                                "v-col",
                                {
                                  class:
                                    _vm.product.highestDiscountedRate >= 3 ||
                                    _vm.product.lowestDiscountedRate >= 3
                                      ? "d-visible"
                                      : "d-invisible",
                                  attrs: {
                                    cols: "2",
                                    sm: "1",
                                    md: "2",
                                    lg: "2",
                                    xl: "1",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "productContent-discountRate rounded-lg py-1 text-center text-caption text_light_gray--text",
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "white--text text-caption text-uppercase font-weight-bold px-1 text-center",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("label.upTo")) +
                                              " "
                                          ),
                                          _c("br"),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.product
                                                  .highestDiscountedRate
                                              ) +
                                              "% "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _c(
                            "v-row",
                            {
                              class:
                                ((_vm.marketPrice - _vm.productPrice) /
                                  _vm.marketPrice) *
                                  100 !=
                                0
                                  ? "d-visible"
                                  : "d-invisible",
                              attrs: {
                                align: "center",
                                justify: "space-between",
                                "no-gutters": "",
                              },
                            },
                            [
                              _c("v-col", { attrs: { cols: "5" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "text-caption text-decoration-line-through text_light_gray--text",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$shared.formatCurrency(
                                            _vm.marketPrice
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                              _c(
                                "v-col",
                                {
                                  class:
                                    Math.floor(
                                      ((_vm.marketPrice - _vm.productPrice) /
                                        _vm.marketPrice) *
                                        100
                                    ) >= 3
                                      ? "d-visible"
                                      : "d-invisible",
                                  attrs: {
                                    cols: "2",
                                    sm: "1",
                                    md: "21",
                                    lg: "2",
                                    xl: "1",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "productContent-discountRate rounded-lg py-1 text-center text-caption text_light_gray--text",
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "white--text text-caption text-uppercase font-weight-bold",
                                        },
                                        [
                                          _vm._v(
                                            "- " +
                                              _vm._s(
                                                Math.floor(
                                                  ((_vm.marketPrice -
                                                    _vm.productPrice) /
                                                    _vm.marketPrice) *
                                                    100
                                                )
                                              ) +
                                              "%"
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-space-between align-center",
                        },
                        [
                          _c("div", [
                            _vm.product.highestDiscountedPrice ==
                            _vm.product.lowestDiscountedPrice
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "font-weight-bold text-md-h4 text-h5 unbox_red--text my-3 highestDiscountedPrice",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          "" +
                                            _vm.$shared.formatCurrency(
                                              _vm.product.highestDiscountedPrice
                                            )
                                        ) +
                                        " "
                                    ),
                                  ]
                                )
                              : _vm.selectedSku == ""
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "font-weight-bold text-md-h4 text-h5 unbox_red--text my-3 lowestDiscountedPrice",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$shared.formatCurrency(
                                            _vm.product.lowestDiscountedPrice
                                          ) +
                                            " - " +
                                            _vm.$shared.formatCurrency(
                                              _vm.product.highestDiscountedPrice
                                            )
                                        ) +
                                        " "
                                    ),
                                  ]
                                )
                              : _c(
                                  "div",
                                  {
                                    staticClass:
                                      "font-weight-bold text-md-h4 text-h6 unbox_red--text my-3 productPrice",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          "" +
                                            _vm.$shared.formatCurrency(
                                              _vm.productPrice
                                            )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                          ]),
                          _vm.isOutOfStock
                            ? _c("div", [
                                _c(
                                  "div",
                                  { staticClass: "pinky-box text-uppercase" },
                                  [_vm._v(_vm._s(_vm.$t("label.soldOut")))]
                                ),
                              ])
                            : _vm._e(),
                        ]
                      ),
                      _vm.product.description != ""
                        ? _c(
                            "div",
                            { staticClass: "text-caption text-justify" },
                            [_vm._v(_vm._s(_vm.product.description))]
                          )
                        : _vm._e(),
                      _vm.product.insuranceType
                        ? _c(
                            "div",
                            { staticClass: "my-3 gray--text text-body-2" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    "" +
                                      _vm.$t(
                                        "label.aeonCreditInstallmentPlan",
                                        [
                                          _vm.$shared.formatCurrency(
                                            _vm.aeonInstallmentPrice
                                          ),
                                        ]
                                      )
                                  ) +
                                  " "
                              ),
                              _c("v-icon", {
                                staticClass: "stroke--link_text cursor-pointer",
                                attrs: { small: "" },
                                domProps: {
                                  textContent: _vm._s("$exclamationMarkIcon"),
                                },
                                on: { click: _vm.openAeonForm },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.product.variants != undefined &&
                      _vm.product.variants.length > 0 &&
                      _vm.variantOptions.length > 0
                        ? _c(
                            "div",
                            [
                              _c("v-divider", { staticClass: "my-3" }),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "font-weight-bold text-body-2 my-3",
                                },
                                [_vm._v(_vm._s(_vm.$t("label.variations")))]
                              ),
                              _c(
                                "div",
                                _vm._l(
                                  _vm.variantOptions.filter(function (opt) {
                                    return !opt.data.every(function (o) {
                                      return o.disabled
                                    })
                                  }),
                                  function (option) {
                                    return _c(
                                      "v-row",
                                      {
                                        key: "" + option.key,
                                        attrs: {
                                          justify: "space-between",
                                          "no-gutters": "",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            staticClass:
                                              "text-sm-body-1 text-body-2 pt-4",
                                            attrs: { cols: "12", sm: "auto" },
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(_vm._s(option.key)),
                                            ]),
                                          ]
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "mb-4",
                                            attrs: { cols: "12", sm: "9" },
                                          },
                                          [
                                            _c("v-select", {
                                              staticClass:
                                                "rounded-pill productVariant-field pl-sm-4 pl-0 text-sm-body-1 text-body-2",
                                              attrs: {
                                                value:
                                                  _vm.selectedVariant.find(
                                                    function (x) {
                                                      return x.key == option.key
                                                    }
                                                  ) != undefined
                                                    ? _vm.selectedVariant.find(
                                                        function (x) {
                                                          return (
                                                            x.key == option.key
                                                          )
                                                        }
                                                      ).value
                                                    : "",
                                                filled: "",
                                                placeholder:
                                                  _vm.$t("label.pleaseSelect"),
                                                rules:
                                                  _vm.$validation.dropDownListRules(),
                                                items: option.data.filter(
                                                  function (o) {
                                                    return !o.disabled
                                                  }
                                                ),
                                                "append-icon":
                                                  "$chevronDownIcon",
                                                clearable: "",
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.updateSelecedVariant(
                                                    option.key,
                                                    $event
                                                  )
                                                },
                                                "click:clear": function (
                                                  $event
                                                ) {
                                                  return _vm.updateSelecedVariant(
                                                    option.key,
                                                    ""
                                                  )
                                                },
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.noSelectVariantError
                        ? _c(
                            "div",
                            { staticClass: "red--text text-body-2 mt-4" },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("label.pleaseSelectProductVariant")
                                )
                              ),
                            ]
                          )
                        : _vm._e(),
                      _c("v-divider", { staticClass: "my-3 mt-6" }),
                      _vm.product.insuranceType &&
                      _vm.productPrice < 8000.01 &&
                      _vm.productPrice > 0
                        ? _c(
                            "v-row",
                            {
                              staticClass: "mb-2",
                              attrs: { "no-gutters": "", align: "top" },
                            },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "auto" } },
                                [
                                  _c("v-checkbox", {
                                    staticClass: "mt-0",
                                    attrs: { "hide-details": "" },
                                    model: {
                                      value: _vm.addOnInsurance,
                                      callback: function ($$v) {
                                        _vm.addOnInsurance = $$v
                                      },
                                      expression: "addOnInsurance",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("v-col", [
                                _c("p", { staticClass: "mb-0" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("label.insurancePlan", [
                                          _vm.$shared.formatCurrency(
                                            _vm.getInsuranceTierPrice()
                                          ),
                                        ])
                                      ) +
                                      " "
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "link-text cursor-pointer",
                                      on: { click: _vm.openProtectionPlanPage },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("label.tncsApply")) +
                                          " "
                                      ),
                                      _c("v-icon", {
                                        staticClass: "stroke--link_text",
                                        attrs: { small: "" },
                                        domProps: {
                                          textContent: _vm._s(
                                            "$exclamationMarkIcon"
                                          ),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                              ]),
                              _c(
                                "v-col",
                                { staticClass: "py-2", attrs: { cols: "12" } },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "font-weight-bold text-sm-body-1 text-body-2 unbox_red--text mb-2",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$shared.formatCurrency(
                                            _vm.getInsuranceTierPrice()
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.isOutOfStock
                        ? _c(
                            "div",
                            [
                              _c("div", [
                                _c("span", { staticClass: "pinky-box" }, [
                                  _vm._v(_vm._s(_vm.$t("label.note")) + ":"),
                                ]),
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "gray--text mt-4 mb-16 text-body-2",
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("label.weAreSorrySoldOut"))
                                  ),
                                ]
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass:
                                    "rounded-lg font-weight-bold text-md-h6 text-body-2 py-md-8 py-6 white--text",
                                  attrs: { block: "", color: "black" },
                                  on: { click: _vm.contactUnboxWhatsapp },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("label.contactUnbox")) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm.productStock !== null &&
                          _vm.productStock.display_sku == this.selectedSku &&
                          _vm.productStock.quantity <= 0 &&
                          _vm.isPreOrderItem
                        ? _c("div", [
                            _c(
                              "div",
                              { staticClass: "mt-6" },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass:
                                      "rounded-lg font-weight-bold ext-md-h6 text-body-2 py-md-8 py-6",
                                    attrs: {
                                      outlined: "",
                                      block: "",
                                      color: "black",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("label.soldOut")) +
                                        " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ])
                        : _c("div", [
                            !_vm.isPreOrderItem
                              ? _c(
                                  "div",
                                  { staticClass: "mt-6" },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass:
                                          "rounded-lg font-weight-bold text-md-h6 text-body-2 py-md-8 py-6",
                                        attrs: {
                                          outlined: "",
                                          disabled:
                                            _vm.productStock.quantity === 0,
                                          block: "",
                                          color: "black",
                                        },
                                        on: { click: _vm.addToCart },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("label.addToCart")) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "div",
                              { staticClass: "mt-6" },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass:
                                      "rounded-lg font-weight-bold white--text text-md-h6 text-body-2 py-md-8 py-6",
                                    attrs: {
                                      block: "",
                                      disabled:
                                        !_vm.isPreOrderItem &&
                                        _vm.productStock.quantity === 0,
                                      color: "unbox_red",
                                    },
                                    on: { click: _vm.buyNow },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.isPreOrderItem
                                            ? _vm.$t("label.preOrderNow")
                                            : _vm.$t("label.buyNow")
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }